<template>
  <cz-menu-button
    v-on="$listeners"
    v-bind="$attrs"
    :menu-max-width="350"
    flat
    color="primary"
    :button-title="order.orderNumber.toString()"
    :menuItems="orderMenuItems"
  />
</template>

<script>
import { CzMenuButton } from '@/components';
import { formatAmountWithCurrency } from '@/shared/services/currency.service';
import { formatDateToDisplay } from '@/shared/services/dates.service';
export default {
  name: 'OrderInfoMenu',
  components: {
    CzMenuButton
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    showOrderLink: {
      type: Boolean
    }
  },
  computed: {
    orderMenuItems() {
      if (!this.order) {
        return [];
      }

      const items = [
        {
          title: this.$t('customer.orders.orderNumber'),
          subtitle: this.order.orderNumber
        },
        {
          title: this.$t('customer.orders.vendorId'),
          subtitle: this.order.vendorId
        },
        {
          title: this.$t('customer.orders.issueDate'),
          subtitle: formatDateToDisplay(this.order.issueDate)
        },
        {
          title: this.$t('customer.orders.createdAt'),
          subtitle: formatDateToDisplay(this.order.createdAt)
        },
        {
          title: this.$t('customer.orders.totalAmount'),
          subtitle: formatAmountWithCurrency(this.order.totalAmount)
        },
        {
          title: this.$t('customer.orders.amountLeft'),
          subtitle: formatAmountWithCurrency(this.order.amountLeft)
        }
      ];

      if (this.order.title) {
        items.unshift({
          title: this.$t('customer.orders.orderShortDesc'),
          subtitle: this.order.title
        });
      }

      if (this.order.description) {
        items.push({
          title: this.$t('customer.orders.shortDesc'),
          subtitle: this.order.description
        });
      }

      if (this.order.orderDocument?.url) {
        items.push({
          title: this.$t('customer.orders.orderDocument'),
          subtitle: this.$t('customer.orders.viewOrderDocument'),
          handler: () => {
            window.open(this.order.orderDocument.url, '_blank');
          }
        });
      }

      if (this.showOrderLink) {
        items.push({
          title: this.$t('customer.orders.orderFullDisplayTitle'),
          subtitle: this.$t('customer.orders.orderFullDisplaySubtitle'),
          handler: () => {
            this.$emit('show-order');
          }
        });
      }

      return items;
    }
  }
};
</script>

<style></style>
