<template>
  <cz-menu-button
    :menu-max-width="350"
    flat
    color="primary"
    :button-title="customerAccount.companyName"
    :menuItems="customerMenuItems"
  />
</template>

<script>
import { CzMenuButton } from '@/components';
export default {
  name: 'CustomerInfoMenu',
  components: {
    CzMenuButton
  },
  props: {
    customerAccount: {
      type: Object,
      required: true
    }
  },
  computed: {
    customerMenuItems() {
      if (!this.customerAccount) {
        return [];
      }

      return [
        {
          title: this.$t('vendor.orderSubmissions.customerName'),
          subtitle: this.customerAccount.companyName
        },
        {
          title: this.$t('vendor.orderSubmissions.businessId'),
          subtitle: this.customerAccount.businessId
        },
        {
          title: this.$t('vendor.orderSubmissions.contactPersonName'),
          subtitle: this.customerAccount.contactPersonName
        },
        {
          title: this.$t('vendor.orderSubmissions.contactPersonEmail'),
          subtitle: this.customerAccount.contactPersonEmail
        },
        {
          title: this.$t('vendor.orderSubmissions.contactPersonPhone'),
          subtitle:
            this.customerAccount.contactPersonPhone || this.$t('common.na')
        }
      ];
    }
  }
};
</script>

<style></style>
